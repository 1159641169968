import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Admin_team_settings= () => {

    const [teams, setTeams] = useState([]);
    

    const [ad, setad] = useState("");
    const [param1_constant, setparam1_constant] = useState(1);
    const [param2_constant, setparam2_constant] = useState(1);
    const [param3_constant, setparam3_constant] = useState(1);
    const [param4_constant, setparam4_constant] = useState(1);
    const [param6_constant, setparam6_constant] = useState(1);

    const [param1, setparam1] = useState(1);
    const [param2, setparam2] = useState(1);
    const [param3, setparam3] = useState(1);
    const [param4, setparam4] = useState(1);
    const [param6, setparam6] = useState(1);


    const [total_duration, settotal_duration] = useState(0);
    const handtotal_duration = (e) => {settotal_duration((e.target.value))}



    const handad = (e) => {setad((e.target.value))}
    const handparam1_constant = (e) => {setparam1_constant((e.target.value))}
    const handparam2_constant = (e) => {setparam2_constant((e.target.value))}
    const handparam3_constant = (e) => {setparam3_constant((e.target.value))}
    const handparam4_constant = (e) => {setparam4_constant((e.target.value))}
    const handparam6_constant = (e) => {setparam6_constant((e.target.value))}

    const handparam1 = (e) => {setparam1((e.target.value))}
    const handparam2 = (e) => {setparam2((e.target.value))}
    const handparam3 = (e) => {setparam3((e.target.value))}
    const handparam4 = (e) => {setparam4((e.target.value))}
    const handparam6 = (e) => {setparam6((e.target.value))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getParams()
        

    }, []);

    const getParams = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_team_params' )
            .then(function (response) {

                console.log(response);
                if(response.data.result === 'Success'){
                    setparam1_constant(response.data.params.param1)
                    setparam2_constant(response.data.params.param2)
                    setparam3_constant(response.data.params.param3)
                    setparam4_constant(response.data.params.param4)
                    setparam6_constant(response.data.params.oyuncu_yildiz)
                    settotal_duration(response.data.duration)

                }

            });
    }

    const savecosnt = () => {

        let data = {
            param1_constant : param1_constant,
            param2_constant : param2_constant,
            param3_constant : param3_constant,
            param4_constant : param4_constant,
            param6_constant : param6_constant,
            total_duration : total_duration,
        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_team_const_save',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    const calc = () => {
        axios.post(process.env.REACT_APP_API_BASE+'admin_team_point_calc'
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully calculated!");

            }else{
                showError("An error occured! Please try again.");
            }

        });
    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>PARAMETERS</div>


                            <div className='row mt-2'>
                                <div className='col-3'>

                                    <div className='f_14'>Yıldız: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param1_constant} onChange={handparam1_constant}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Plak: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param2_constant} onChange={handparam2_constant}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Küre: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param3_constant} onChange={handparam3_constant}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Süre: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param4_constant} onChange={handparam4_constant}  /></div>
                                    
                                </div>
                            </div>

                            

                            <div className='row mt-5'>
                                <div className='col-4'>
                                    <div className='f_14'>Toplam Oyun Süresi: (dakika)</div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={total_duration} onChange={handtotal_duration}  /></div>
                                    
                                </div>

                                <div className='col-4'>
                                    <div className='f_14'>Eksik oyuncu kadar eklenecek yıldız: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param6_constant} onChange={handparam6_constant}  /></div>
                                    
                                </div>
                                
                            </div>

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={savecosnt} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>

                    <div className="card mt-3 border-0 mx-auto color_s " >

                        <div className="card-body text-center p-3">
                            Calculate all team points <u>again</u> according to new settings!
                            <div className='mt-3'><button onClick={calc} className='btn btn-success '>Calculate Again</button></div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Admin_team_settings;