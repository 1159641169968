import './Css/public.css';
import { Routes, Route, Link } from "react-router-dom";
import Home from './Components/Home'
import HomeTest from './Components/Home_test'
import Adminlogin from './Components/Admin_login'
import Admin_dashboard from './Components/Admin_dashboard'
import Admin_banners from './Components/Admin_banners'
import Admin_header_text from './Components/Admin_header_text'
import Admin_nedir from './Components/Admin_nedir'
import Admin_gallery from './Components/Admin_gallery'
import Admin_teams from './Components/Admin_teams'
import Admin_team_settings from './Components/Admin_team_settings'
import Admin_team_edit from './Components/Admin_team_edit'
import Admin_contact from './Components/Admin_contact'
import Admin_pass from './Components/Admin_pass'




import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/test" element={<HomeTest />} />
          <Route path="/admin_tt" element={<Adminlogin />} />
          <Route path="/admin_tt/dashboard" element={<Admin_dashboard />} />
          <Route path="/admin_tt/banners" element={<Admin_banners />} />
          <Route path="/admin_tt/header_text" element={<Admin_header_text />} />
          <Route path="/admin_tt/nedir" element={<Admin_nedir />} />
          <Route path="/admin_tt/gallery" element={<Admin_gallery />} />
          <Route path="/admin_tt/teams" element={<Admin_teams />} />
          <Route path="/admin_tt/team_setting" element={<Admin_team_settings />} />
          <Route path="/admin_tt/contact" element={<Admin_contact />} />
          <Route path="/admin_tt/team_edit/:id" element={<Admin_team_edit />} />
          <Route path="/admin_tt/pass" element={<Admin_pass />} />

          


        </Routes>
      </div>
  );
}

export default App;
