import React, { useState, useEffect , useRef} from 'react';
import {useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';



const Menu = () => {
    const location = useLocation();  




    useEffect(() => { 

        console.log(location.pathname);



    }, []);


    
    return(

        <div className="card  border-0 mx-auto color_s " >

            <div className="card-body p-2">
                <ul className='dash_menu_ul'>
                    <li className='p-2'>
                        <Link to={'/admin_tt/banners'} className={location.pathname === '/admin_tt/banners' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Header Banner
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/header_text'} className={location.pathname === '/admin_tt/header_text' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Header Text
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/nedir'} className={location.pathname === '/admin_tt/nedir' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Nedir
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/gallery'} className={location.pathname === '/admin_tt/gallery' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Gallery
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/team_setting'} className={location.pathname === '/admin_tt/team_setting' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Team Settings
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/teams'} className={location.pathname === '/admin_tt/teams' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Teams
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/contact'} className={location.pathname === '/admin_tt/contact' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Contact
                        </Link>
                    </li>
                    <li className='p-2'>
                        <Link to={'/admin_tt/pass'} className={location.pathname === '/admin_tt/pass' ? 'btn btn-primary w-100 text-start' : 'btn btn-light w-100 text-start'}>
                        Admin Login Password
                        </Link>
                    </li>
                </ul>
            </div>

        </div>

    );
}

export default Menu;