import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";
import Pagination from 'react-bootstrap/Pagination'

import C_dash_menu from './C_dash_menu'




const Adminteams= () => {

    const [teams, setTeams] = useState([]);
    let [totalPages, setTotalPages] = useState([]);
    let [active, setActive] = useState(1);

    const [ad, setad] = useState("");
    const [param1_constant, setparam1_constant] = useState(1);
    const [param2_constant, setparam2_constant] = useState(1);
    const [param3_constant, setparam3_constant] = useState(1);
    const [param4_constant, setparam4_constant] = useState(1);
    const [param6_constant, setparam6_constant] = useState(1);

    const [param1, setparam1] = useState(1);
    const [param2, setparam2] = useState(1);
    const [param3, setparam3] = useState(1);
    const [param4, setparam4] = useState(20);
    const [param4_sn, setparam4_sn] = useState(0);
    const [param6, setparam6] = useState(6);
    const [param7, setparam7] = useState(0);


    const [total_duration, settotal_duration] = useState(0);



    const handad = (e) => {setad((e.target.value))}
    const handparam1 = (e) => {setparam1((e.target.value))}
    const handparam2 = (e) => {setparam2((e.target.value))}
    const handparam3 = (e) => {setparam3((e.target.value))}
    const handparam4 = (e) => {setparam4((e.target.value))}
    const handparam4_sn = (e) => {setparam4_sn((e.target.value))}
    const handparam6 = (e) => {setparam6((e.target.value))}
    const handparam7 = (e) => {setparam7((e.target.value))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getParams()
        getTeams()


    }, []);

    const getParams = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_team_params' )
            .then(function (response) {

                if(response.data.result === 'Success'){
                    setparam1_constant(response.data.params.param1)
                    setparam2_constant(response.data.params.param2)
                    setparam3_constant(response.data.params.param3)
                    setparam4_constant(response.data.params.param4)
                    setparam6_constant(response.data.params.oyuncu_yildiz)
                    settotal_duration(response.data.duration)
                }

            });
    }

    const getTeams = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_teams' )
            .then(function (response) {


                if(response.data.result === 'Success'){
                    setTeams(response.data.teams)

                    let total = Math.ceil(response.data.teams.length / 50);

                    let _tempItems = [];
                    for (let number = 1; number <= total; number++) {
                        _tempItems.push(number);
                    }
                    setTotalPages(_tempItems);

                }else{
                }

            });
    }

    const addTeam = () => {


        var formData = new FormData();
        formData.append("file", file);
        formData.append("ad", ad);
        formData.append("param1", param1);
        formData.append("param2", param2);
        formData.append("param3", param3);
        formData.append("param4", param4);
        formData.append("param4_sn", param4_sn);
        formData.append("param6", param6);
        formData.append("param7", param7);


        axios.post(process.env.REACT_APP_API_BASE+'admin_team_add', formData, { headers: {'Content-Type': 'multipart/form-data'}
        }).then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");
                getTeams()

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    const [file, setfile] = useState(null);

    const onchangeFile = (e) => {
        setfile(e.target.files[0]);
    }

    const deleteTeam = (id) => {
        let data = {
            id : id
        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_team_delete',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully deleted!");
                getTeams()

            }else{
                showError("An error occured! Please try again.");
            }

        });
    }

    const changePage = (i) => {
        setActive(i);
    }
    const checkactive = (number) => {
    
        if(number === active){
            return " active "
        }else{
            return ""
        }
    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>ADD TEAM</div>


                            <div className='row mt-2'>
                                <div className='col-12 mb-3'>

                                    <div className='f_14'>Name: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={ad} onChange={handad}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Yıldız: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param1} onChange={handparam1}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Plak: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param2} onChange={handparam2}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Küre: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param3} onChange={handparam3}  /></div>
                                    
                                </div>


                                <div className='col-3 '>

                                    <div className='f_14 '>Oyuncu Sayısı: (max 6) </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param6} onChange={handparam6}  /></div>

                                </div>

                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Geçen Süre: (dakika) </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param4} onChange={handparam4}  /></div>

                                </div>

                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Geçen Süre: (saniye) </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param4_sn} onChange={handparam4_sn}  /></div>

                                </div>


                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Ekstra Puan: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param7} onChange={handparam7}  /></div>

                                </div>

                                
                                <div className="col-12  mt-4">
                                    <div className='f_14 '>Photo: (Recommended : 1200px width)</div> 

                                    <input onChange={onchangeFile} type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                                    
                                </div>
                            </div>

                            

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={addTeam} className="btn btn-primary f_16 w600">ADD</a>
                            </div>


                            
                        </div>
                    </div>

                    
                    <div className="card  border-0 mx-auto color_s mt-4" >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>TEAMS</div>


                            <table className='table '>
                                    <tbody>
                                        {teams.slice((active-1)*50, ((active-1)*50) + 50).map((val,index) => (
                                            <tr key={index}>
                                                <td>{((active-1)*50) + index+1}</td>
                                                <td>{val.name}</td>
                                                <td>{val.point} P.</td>
                                                <td>
                                                    {val.image !== "" &&
                                                    <div>
                                                        <a className='btn btn-sm btn-outline-dark' target={'_blank'} href={process.env.REACT_APP_API_BASE + "uploads/" + val.image}>PHOTO</a>
                                                    </div>
                                                    }
                                                </td>
                                                <td className='text-end'>

                                                <a className='btn btn-sm btn-warning me-2'  href={"/admin_tt/team_edit/" + val.id}>EDIT</a>

                                                <a className='btn btn-sm btn-danger'  onClick={() => { window.confirm( 'Are you sure?', ) && deleteTeam( val.id ); }}>DELETE</a>

                                                </td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>

                                <div className='px-3'>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <Pagination >
                                                {totalPages.map((item, index) => (
                                                    <Pagination.Item key={item} onClick={() => changePage(item)} className={checkactive(item)}>
                                                        {item}
                                                    </Pagination.Item>
                                                ))}
                                            </Pagination>
                                        </div>
                                        <div className='col-2 text-end'>
                                        </div>
                                    </div>
                                    
                                    
                                </div>



                        </div>
                    </div>



                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Adminteams;