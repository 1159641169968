import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Adminnedir= () => {

    const [lang, setlang] = useState("az");
    

    const [sure_az, setsure_az] = useState("");
    const [yas_az, setyas_az] = useState("");
    const [kisi_az, setkisi_az] = useState("");
    const [aciklama_az, setaciklama_az] = useState("");

    const [sure_en, setsure_en] = useState("");
    const [yas_en, setyas_en] = useState("");
    const [kisi_en, setkisi_en] = useState("");
    const [aciklama_en, setaciklama_en] = useState("");

    const [sure_rus, setsure_rus] = useState("");
    const [yas_rus, setyas_rus] = useState("");
    const [kisi_rus, setkisi_rus] = useState("");
    const [aciklama_rus, setaciklama_rus] = useState("");



    const handlesure_az = (e) => {setsure_az((e.target.value))}
    const handleyas_az = (e) => {setyas_az((e.target.value))}
    const handlekisi_az = (e) => {setkisi_az((e.target.value))}
    const handleaciklama_az = (e) => {setaciklama_az((e.target.value))}

    const handlesure_rus = (e) => {setsure_rus((e.target.value))}
    const handleyas_rus = (e) => {setyas_rus((e.target.value))}
    const handlekisi_rus = (e) => {setkisi_rus((e.target.value))}
    const handleaciklama_rus = (e) => {setaciklama_rus((e.target.value))}

    const handlesure_en = (e) => {setsure_en((e.target.value))}
    const handleyas_en = (e) => {setyas_en((e.target.value))}
    const handlekisi_en = (e) => {setkisi_en((e.target.value))}
    const handleaciklama_en = (e) => {setaciklama_en((e.target.value))}




    const handlelang = (e) => {setlang((e))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getNedir()


    }, []);

    const getNedir = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_nedir' )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    //setBanners(response.data.banners)

                    setsure_az(response.data.nedir.sure_az)
                    setkisi_az(response.data.nedir.kisi_az)
                    setaciklama_az(response.data.nedir.aciklama_az)
                    setyas_az(response.data.nedir.yas_az)

                    setsure_en(response.data.nedir.sure_en)
                    setkisi_en(response.data.nedir.kisi_en)
                    setaciklama_en(response.data.nedir.aciklama_en)
                    setyas_en(response.data.nedir.yas_en)

                    setsure_rus(response.data.nedir.sure_rus)
                    setkisi_rus(response.data.nedir.kisi_rus)
                    setaciklama_rus(response.data.nedir.aciklama_rus)
                    setyas_rus(response.data.nedir.yas_rus)

                }else{
                }

            });
    }

    const save1Click = () => {

        let data = {
            sure_az : sure_az,
            kisi_az : kisi_az,
            yas_az : yas_az,
            aciklama_az : aciklama_az,
            sure_rus : sure_rus,
            kisi_rus : kisi_rus,
            yas_rus : yas_rus,
            aciklama_rus : aciklama_rus,
            sure_en : sure_en,
            kisi_en : kisi_en,
            yas_en : yas_en,
            aciklama_en : aciklama_en,
        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_nedir_save',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>NEDİR</div>

                            <div className='mt-3'>
                                <button onClick={()=>handlelang('az')} className={lang === 'az' ? 'btn btn-success' : 'btn btn-light'}>AZ</button>
                                <button onClick={()=>handlelang('rus')} className={lang === 'rus' ? 'btn btn-success mx-3' : 'btn btn-light mx-3'}>RUS</button>
                                <button onClick={()=>handlelang('en')} className={lang === 'en' ? 'btn btn-success' : 'btn btn-light'}>EN</button>
                            </div>

                            <div className=' mt-3'>
                                {lang === 'az' &&
                                    <>
                                        <div className='f_14'>SÜRE: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sure_az} onChange={handlesure_az}  /></div>
                                    
                                        <div className='f_14 mt-2'>YAŞ: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={yas_az} onChange={handleyas_az}  /></div>
                                    
                                        <div className='f_14 mt-2'>KİŞİ SAYISI: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={kisi_az} onChange={handlekisi_az}  /></div>

                                        <div className='f_14 mt-2'>AÇIKLAMA: </div> 
                                        <div><textarea type="text" rows="3" className="form-control f_14 border_grey" value={aciklama_az} onChange={handleaciklama_az}  ></textarea></div>
                                    </>  

                                }
                                {lang === 'rus' &&
                                    <>
                                        <div className='f_14'>SÜRE: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sure_rus} onChange={handlesure_rus}  /></div>
                                    
                                        <div className='f_14 mt-2'>YAŞ: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={yas_rus} onChange={handleyas_rus}  /></div>
                                    
                                        <div className='f_14 mt-2'>KİŞİ SAYISI: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={kisi_rus} onChange={handlekisi_rus}  /></div>

                                        
                                        <div className='f_14 mt-2'>AÇIKLAMA: </div> 
                                        <div><textarea type="text" rows="3" className="form-control f_14 border_grey" value={aciklama_rus} onChange={handleaciklama_rus}  ></textarea></div>

                                    </>
                                }
                                {lang === 'en' &&
                                    <>
                                        <div className='f_14'>SÜRE: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sure_en} onChange={handlesure_en}  /></div>
                                    
                                        <div className='f_14 mt-2'>YAŞ: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={yas_en} onChange={handleyas_en}  /></div>
                                    
                                        <div className='f_14 mt-2'>KİŞİ SAYISI: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={kisi_en} onChange={handlekisi_en}  /></div>

                                        
                                        <div className='f_14 mt-2'>AÇIKLAMA: </div> 
                                        <div><textarea type="text" rows="3" className="form-control f_14 border_grey" value={aciklama_en} onChange={handleaciklama_en}  ></textarea></div>

                                    </>
                                }
                            
                            </div>

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={save1Click} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Adminnedir;