import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";
import { useParams } from "react-router-dom";

import C_dash_menu from './C_dash_menu'




const Adminteamse= () => {

    const {id} = useParams();

    const [team, setTeam] = useState(null);
    

    const [ad, setad] = useState("");

    const [param1, setparam1] = useState(1);
    const [param2, setparam2] = useState(1);
    const [param3, setparam3] = useState(1);
    const [param4, setparam4] = useState(1);
    const [param4_sn, setparam4_sn] = useState(1);
    const [param6, setparam6] = useState(6);
    const [extra, setextra] = useState(0);
    const [image, setimage] = useState("");

    const handad = (e) => {setad((e.target.value))}
    const handparam1 = (e) => {setparam1((e.target.value))}
    const handparam2 = (e) => {setparam2((e.target.value))}
    const handparam3 = (e) => {setparam3((e.target.value))}
    const handparam4 = (e) => {setparam4((e.target.value))}
    const handparam4_sn = (e) => {setparam4_sn((e.target.value))}
    const handparam6 = (e) => {setparam6((e.target.value))}
    const handextra = (e) => {setextra((e.target.value))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getTeam()


    }, []);



    const getTeam = () => {

        
        let data = {
            id : id
        };

        axios.post(process.env.REACT_APP_API_BASE+'admin_team',JSON.stringify(data) )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    setTeam(response.data.team)
                    setad(response.data.team.name)
                    setparam1(response.data.team.param1)
                    setparam2(response.data.team.param2)
                    setparam3(response.data.team.param3)
                    setparam4(response.data.team.param4)
                    setparam4_sn(response.data.team.param4_sn)
                    setparam6(response.data.team.oyuncu)
                    setextra(response.data.team.extra)
                    setimage(response.data.team.image)

                }else{
                }

            });
    }

    const saveTeam = () => {


        var formData = new FormData();
        formData.append("file", file);
        formData.append("ad", ad);
        formData.append("param1", param1);
        formData.append("param2", param2);
        formData.append("param3", param3);
        formData.append("param4", param4);
        formData.append("param4_sn", param4_sn);
        formData.append("param6", param6);
        formData.append("extra", extra);
        formData.append("id", id);



        axios.post(process.env.REACT_APP_API_BASE+'admin_team_save', formData, { headers: {'Content-Type': 'multipart/form-data'}
        }).then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");
                getTeam()

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    const [file, setfile] = useState(null);

    const onchangeFile = (e) => {
        setfile(e.target.files[0]);
    }
    
    const deleteIm = () => {


        let data = {
            id : id
        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_delete_team_image',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Image successfully deleted!");
                getTeam()

            }else{
                showError("Your email address or password is wrong! Please try again.");
            }

        });
    }


    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>EDIT TEAM : {ad}</div>


                            <div className='row mt-2'>
                                <div className='col-12 mb-3'>

                                    <div className='f_14'>Name: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={ad} onChange={handad}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Yıldız: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param1} onChange={handparam1}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Plak: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param2} onChange={handparam2}  /></div>
                                    
                                </div>
                                <div className='col-3'>

                                    <div className='f_14 '>Küre: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param3} onChange={handparam3}  /></div>
                                    
                                </div>


                                <div className='col-3 '>

                                    <div className='f_14 '>Oyuncu Sayısı:  </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param6} onChange={handparam6}  /></div>
                                    
                                </div>

                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Geçen Süre: (dakika) </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param4} onChange={handparam4}  /></div>
                                    
                                </div>

                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Geçen Süre: (saniye) </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={param4_sn} onChange={handparam4_sn}  /></div>

                                    </div>



                                <div className='col-3 mt-4'>

                                    <div className='f_14 '>Extra Puan:  </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={extra} onChange={handextra}  /></div>
                                    
                                </div>

                                
                                <div className="col-12  mt-4">
                                    <div className='f_14 '>Photo: (Recommended : 1200px width)</div> 

                                    {image !== "" &&
                                    <div className='mb-2'>
                                        <img src={process.env.REACT_APP_API_BASE + "uploads/" + image} width={80} />
                                        
                                        <button onClick={deleteIm} className='btn btn-sm btn-danger' >delete</button>
                                    </div>
                                    }

                                    <input onChange={onchangeFile} type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                                    
                                </div>
                            </div>

                            

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={saveTeam} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>

    


                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Adminteamse;