const eno =  {

    "nedir" : "ЧТО",
    "sure" : "ВРЕМЯ",
    "yas" : "ВОЗРАСТ",
    "kisi" : "ИГРОКИ",
    "puan_tabloso" : "ТАБЛО",
    "sampiyon_takimlar" : "КОМАНДЫ-ЧЕМПИОНЫ",
    "galeri" : "ГАЛЕРЕЯ",
    "iletisim" : "КОНТАКТ",
    "adres" : "Адрес",
    "telefon" : "Телефон",
    "tum_zaman" : "Все",
    "son_hafta" : "Прошлая Неделя"
    

}


export default eno;