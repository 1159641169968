import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Admingallerys = () => {

    const [file, setfile] = useState(null);
    const [gallerys, setgallerys] = useState([]);

    const onchangeFile = (e) => {
        setfile(e.target.files[0]);
    }

    const upload = () => {

        let _run = false;
        if(file != null){ _run = true }

        if(_run){
            
            var formData = new FormData();
            formData.append("file", file);

            axios.post(process.env.REACT_APP_API_BASE+'admin_gallery_add', formData, { headers: {'Content-Type': 'multipart/form-data'}
            }).then(function (response) {

                

                if(response.data.result === "Success"){
                    getgallerys();
                    showSucess("Image uploaded succesfully!");

                    console.log("başarı");

                }else{
                    showError("There is an error. Please try again!");
                }
            });
        }

    }

    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getgallerys()


    }, []);

    const getgallerys = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_gallerys' )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    setgallerys(response.data.gallery)
                }else{
                }

            });
    }

    const deleteImage = (id) => {
        console.log(id);

        let data = {
            id : id
        };

        axios.post(process.env.REACT_APP_API_BASE+'admin_gallery_delete',JSON.stringify(data) )
            .then(function (response) {

            if(response.data.result === 'Success'){
                getgallerys()

            }else{
            }

        });
    }

    const up = (gelen) => {

        let data = {
            id : gelen
        };

        axios.post(process.env.REACT_APP_API_BASE+'admin_gallery_up',JSON.stringify(data) )
            .then(function (response) {

            if(response.data.result === 'Success'){
                getgallerys()

            }else{
            }

        });

    }
    const down = (gelen) => {
        let data = {
            id : gelen
        };

        axios.post(process.env.REACT_APP_API_BASE+'admin_gallery_down',JSON.stringify(data) )
            .then(function (response) {

            if(response.data.result === 'Success'){
                getgallerys()

            }else{
            }

        });

    }
    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>Gallery (Recommended : 1200px width)</div>

                            <div className='mt-3'>

                            <div className="input-group">
                                <input onChange={onchangeFile} type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" />
                                <button onClick={upload} className="btn btn-success" type="button" id="inputGroupFileAddon04">UPLOAD</button>
                            </div>

                            <hr className='my-4' />

                                <table className='table '>
                                    <tbody>
                                        {gallerys.map((val,index) => (
                                            <tr key={index}>
                                                <td width={20}>
                                                    <a href='#' onClick={() => up(val.id)}><TbChevronUp size={26} /></a>
                                                    <a href='#' onClick={() => down(val.id)}><TbChevronDown size={26} /></a>
                                                </td>
                                                <td><img src={process.env.REACT_APP_API_BASE + "uploads/c_" + val.image} width={80} /></td>
                                                <td className='text-end'>
                                                    <a href='#' onClick={() => { window.confirm( 'Are you sure?', ) && deleteImage( val.id ); }} className='btn btn-sm btn-dark'>DELETE</a>
                                                </td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>

                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Admingallerys;