const eno =  {

    "nedir" : "NEDİR",
    "sure" : "SÜRE",
    "yas" : "YAŞ",
    "kisi" : "KİŞİ SAYISI",
    "puan_tabloso" : "PUAN TABLOSU",
    "sampiyon_takimlar" : "ŞAMPİYON TAKIMLAR",
    "galeri" : "GALERİ",
    "iletisim" : "İLETİŞİM",
    "adres" : "Adres",
    "telefon" : "Telefon",
    "tum_zaman" : "Tüm Zamanlar",
    "son_hafta" : "Son Hafta"
    

}


export default eno;