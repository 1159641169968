import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Adminhe= () => {

    const [lang, setlang] = useState("az");
    

    const [sentence1_az, setsentence1_az] = useState("");
    const [sentence2_az, setsentence2_az] = useState("");
    const [sentence3_az, setsentence3_az] = useState("");
    
    const [sentence1_rus, setsentence1_rus] = useState("");
    const [sentence2_rus, setsentence2_rus] = useState("");
    const [sentence3_rus, setsentence3_rus] = useState("");

    const [sentence1_en, setsentence1_en] = useState("");
    const [sentence2_en, setsentence2_en] = useState("");
    const [sentence3_en, setsentence3_en] = useState("");


    const handlesentence1_az = (e) => {setsentence1_az((e.target.value))}
    const handlesentence2_az = (e) => {setsentence2_az((e.target.value))}
    const handlesentence3_az = (e) => {setsentence3_az((e.target.value))}
 

    const handlesentence1_rus = (e) => {setsentence1_rus((e.target.value))}
    const handlesentence2_rus = (e) => {setsentence2_rus((e.target.value))}
    const handlesentence3_rus = (e) => {setsentence3_rus((e.target.value))}
 

    const handlesentence1_en = (e) => {setsentence1_en((e.target.value))}
    const handlesentence2_en = (e) => {setsentence2_en((e.target.value))}
    const handlesentence3_en = (e) => {setsentence3_en((e.target.value))}
 


    const handlelang = (e) => {setlang((e))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getHe()


    }, []);

    const getHe = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_header_text' )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    //setBanners(response.data.banners)

                    setsentence1_az(response.data.header_info.sentence1_az)
                    setsentence2_az(response.data.header_info.sentence2_az)
                    setsentence3_az(response.data.header_info.sentence3_az)
                    
                    setsentence1_rus(response.data.header_info.sentence1_rus)
                    setsentence2_rus(response.data.header_info.sentence2_rus)
                    setsentence3_rus(response.data.header_info.sentence3_rus)
                    
                    setsentence1_en(response.data.header_info.sentence1_en)
                    setsentence2_en(response.data.header_info.sentence2_en)
                    setsentence3_en(response.data.header_info.sentence3_en)
                    

                }else{
                }

            });
    }

    const save1Click = () => {

        let data = {
            sentence1_az : sentence1_az,
            sentence2_az : sentence2_az,
            sentence3_az : sentence3_az,
            sentence1_rus : sentence1_rus,
            sentence2_rus : sentence2_rus,
            sentence3_rus : sentence3_rus,
            sentence1_en : sentence1_en,
            sentence2_en : sentence2_en,
            sentence3_en : sentence3_en,

        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_header_text_save',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>HEADER TEXTS</div>

                            <div className='mt-3'>
                                <button onClick={()=>handlelang('az')} className={lang === 'az' ? 'btn btn-success' : 'btn btn-light'}>AZ</button>
                                <button onClick={()=>handlelang('rus')} className={lang === 'rus' ? 'btn btn-success mx-3' : 'btn btn-light mx-3'}>RUS</button>
                                <button onClick={()=>handlelang('en')} className={lang === 'en' ? 'btn btn-success' : 'btn btn-light'}>EN</button>
                            </div>

                            <div className=' mt-3'>
                                {lang === 'az' &&
                                    <>
                                        <div className='f_14'>SENTENCE 1: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence1_az} onChange={handlesentence1_az}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 2: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence2_az} onChange={handlesentence2_az}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 3: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence3_az} onChange={handlesentence3_az}  /></div>
                                    </>  

                                }
                                {lang === 'rus' &&
                                    <>
                                        <div className='f_14'>SENTENCE 1: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence1_rus} onChange={handlesentence1_rus}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 2: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence2_rus} onChange={handlesentence2_rus}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 3: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence3_rus} onChange={handlesentence3_rus}  /></div>

                                    </>
                                }
                                {lang === 'en' &&
                                    <>
                                        <div className='f_14'>SENTENCE 1: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence1_en} onChange={handlesentence1_en}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 2: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence2_en} onChange={handlesentence2_en}  /></div>
                                    
                                        <div className='f_14 mt-2'>SENTENCE 3: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={sentence3_en} onChange={handlesentence3_en}  /></div>

                                    </>
                                }
                            
                            </div>

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={save1Click} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Adminhe;