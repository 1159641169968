const eno =  {

    "nedir" : "WHAT",
    "sure" : "DURATION",
    "yas" : "AGE",
    "kisi" : "PLAYERS",
    "puan_tabloso" : "SCORBOARD",
    "sampiyon_takimlar" : "CHAMPION TEAMS",
    "galeri" : "GALLERY",
    "iletisim" : "CONTACT",
    "adres" : "Address",
    "telefon" : "Phone",
    "tum_zaman" : "All",
    "son_hafta" : "Last Week"
    

}


export default eno;