import React, { useState, useEffect , useRef} from 'react';

import { ToastContainer, toast } from 'react-toastify';

import C_dash_menu from './C_dash_menu'

const AdminDash = () => {
    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'></div>
            </div>


        </div>

    );
}

export default AdminDash;