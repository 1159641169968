import React, { useState, useEffect , useRef} from 'react';

import { ToastContainer, toast } from 'react-toastify';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";

import { useCookies } from 'react-cookie';


import { Link } from 'react-router-dom';

const Home = () => {

    const config = {
        apiKey: "AIzaSyD7qAuLq4CEARSaTdBPwOufsISIw7jkpQI",
        authDomain: "smd-quiz-ce390.firebaseapp.com",
        databaseURL: "https://smd-quiz-ce390-default-rtdb.firebaseio.com/",
        projectId: "smd-quiz-ce390",
        storageBucket: "smd-quiz-ce390.appspot.com",
        messagingSenderId: "160488488569",
        appId: "1:160488488569:web:b7605e0f6f7174a1d41ea8"
    };

    let vidRef = useRef(null);

    const [girisAcik, setGirisAcik] = useState(1);


    const f_app = initializeApp(config);
    const db = getDatabase(f_app);
    const dbRef = ref(db);

    const [logins, setLogins] = useState(false);
    const [users, setUsers] = useState([]);
    const [ad, setAd] = useState("");
    const [soyad, setSoyad] = useState("");
    const [email, setEmail] = useState("");

    const handleAd = (e) => {setAd((e.target.value))}
    const handleSoyad = (e) => {setSoyad((e.target.value))}
    const handleEmail = (e) => {setEmail((e.target.value.toLowerCase()))}

    const [vid_width, setVid_width] = useState(1000);
    const [vid_height, setVid_height] = useState(675);



    useEffect(() => { 

        



    }, []);


    
    return(

        <div className=' container py-3 px-4 '>

                TEST



        </div>

    );
}

export default Home;