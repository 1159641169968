import React, { useState, useEffect , useRef} from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'

const Home = () => {
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const handlePass = (e) => {setPassword((e.target.value))}
    const handleEmail = (e) => {setEmail((e.target.value.toLowerCase()))}

    useEffect(() => { 

    }, []);

    const validateEmail = (em) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(em);
    }


    const signInClick = () => {

        let _ready = true

        if(password.length <= 7){_ready = false; showError("Please enter your password!");}
        if(!validateEmail(email)){_ready = false; showError("Please enter a valid email address!");}

        if(_ready){

            let data = {
                member_email : email,
                member_password : password,
            };


            axios.post(process.env.REACT_APP_API_BASE+'admin_login',JSON.stringify(data)
            )
            .then(function (response) {

                if(response.data.result === 'Success'){

                    localStorage.setItem('tepetaklak_admin', "5676e37338d668578a3686ab581abe0f");
                    navigate("/admin_tt/dashboard");

                }else{
                    showError("Your email address or password is wrong! Please try again.");
                }

            });
            
        
        }

    }

    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    
    return(

        <div className=' container py-3 px-4 '>

                    
            <div className="card my-5 border-0 mx-auto color_s " >
                <div className="card-body p-4">
                    <div className="text-center mt-4">
                        <h1 className="w600 f_22  mt-5">Admin Panel</h1>
                    </div>
                        
                    <div className="mb-4 mt-5">
                        <label htmlFor="input_email" className="form-label w500 f_16 mb-1">Email Address</label>
                        <input type="email" className="form-control form-control-lg f_14 py-3 border_grey" value={email} onChange={handleEmail} id="input_email" placeholder="Email Address" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="input_password" className="form-label w500 f_16 mb-1">Password</label>
                        <input type="password" className="form-control form-control-lg f_14 py-3 border_grey" value={password} onChange={handlePass} id="input_password" placeholder="Password" />
                    </div>

                    <div className="d-grid ">
                        <a href="#" onClick={signInClick} className="btn btn-primary f_16 w600 py-3">Log In</a>
                    </div>



                </div>
            </div>


        <ToastContainer />
        </div>

    );
}

export default Home;