import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Admincontact= () => {

    const [lang, setlang] = useState("az");
    

    const [address_az, setaddress_az] = useState("");
    const [address_rus, setaddress_rus] = useState("");
    const [address_en, setaddress_en] = useState("");
    const [tel, settel] = useState("");
    const [altitude, setaltitude] = useState("");
    const [latitude, setlatitude] = useState("");

    const handleaddress_az = (e) => {setaddress_az((e.target.value))}
    const handleaddress_rus = (e) => {setaddress_rus((e.target.value))}
    const handleaddress_en = (e) => {setaddress_en((e.target.value))}
    const handleatel = (e) => {settel((e.target.value))}

    const handlealtitude = (e) => {setaltitude((e.target.value))}
    const handlelatitude = (e) => {setlatitude((e.target.value))}



    const handlelang = (e) => {setlang((e))}



    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getContact()


    }, []);

    const getContact = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_contact' )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    //setBanners(response.data.banners)

                    setaddress_az(response.data.contact.address_az)
                    setaddress_rus(response.data.contact.address_rus)
                    setaddress_en(response.data.contact.address_en)
                    settel(response.data.contact.tel)

                    setaltitude(response.data.contact.altitude)
                    setlatitude(response.data.contact.latitude)


                }else{
                }

            });
    }

    const save1Click = () => {

        let data = {
            address_az : address_az,
            address_rus : address_rus,
            address_en : address_en,
            tel : tel,
            altitude : altitude,
            latitude : latitude
        };


        axios.post(process.env.REACT_APP_API_BASE+'admin_contact_save',JSON.stringify(data)
        )
        .then(function (response) {

            if(response.data.result === 'Success'){

                showSucess("Successfully saved!");

            }else{
                showError("An error occured! Please try again.");
            }

        });

    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>CONTACT</div>


                            <div className=' mt-3'>
                                <>
                                        <div className='f_14'>Address AZ: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={address_az} onChange={handleaddress_az}  /></div>
                                    
                                        <div className='f_14 mt-2'>Address Rus: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={address_rus} onChange={handleaddress_rus}  /></div>
                                    
                                        <div className='f_14 mt-2'>Address En: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={address_en} onChange={handleaddress_en}  /></div>


                                        <div className='f_14 mt-2'>Phone: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={tel} onChange={handleatel}  /></div>

                                        <div className='f_14 mt-2'>Map Altitude: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={altitude} onChange={handlealtitude}  /></div>

                                        <div className='f_14 mt-2'>Map Latitude: </div> 
                                        <div><input type="text" className="form-control f_14 border_grey" value={latitude} onChange={handlelatitude}  /></div>


                                    </>  
                            
                            </div>

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={save1Click} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Admincontact;