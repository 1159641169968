import React, { useState, useEffect , useRef} from 'react';
import { useCookies } from 'react-cookie';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { TbChevronRight, TbChevronLeft, TbMenu2, TbX} from "react-icons/tb";
import GoogleMapReact from 'google-map-react';
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import { Lightbox } from "react-modal-image";


import logo_img from '../Assets/logo.png'
import ev_07 from '../Assets/ev_07.png'
import logo_w from '../Assets/logo_w.png'
import var1 from '../Assets/var1.png'
import var2 from '../Assets/var2.png'
import ph_23 from '../Assets/ph_23.png'
import s1 from '../Assets/s1.png'
import s2 from '../Assets/s2.png'
import s3 from '../Assets/s3.png'
import Map_pin_icon from '../Assets/Map_pin_icon.svg.png';
import jun_37 from '../Assets/jun_37.png';

import flag_az from '../Assets/flag_az.png';
import flag_rus from '../Assets/flag_rus.png';
import flag_en from '../Assets/flag_en.png';

import az from '../Assets/Translates/az';
import rus from '../Assets/Translates/rus';
import en from '../Assets/Translates/en';


const AnyReactComponent = ({ text }) => <img src={Map_pin_icon} width={30} />;


const Home = () => {

    const ref_nedir = useRef(null);
    const ref_puan = useRef(null);
    const ref_galery = useRef(null);
    const ref_contact = useRef(null);
    const gotoNedir = () => {   ref_nedir.current?.scrollIntoView({behavior: 'smooth'});  hide_mobile_menu() };
    const gotoPuan = () => {   ref_puan.current?.scrollIntoView({behavior: 'smooth'});   hide_mobile_menu()};
    const gotoGalery = () => {   ref_galery.current?.scrollIntoView({behavior: 'smooth'});   hide_mobile_menu()};
    const gotoContact = () => {   ref_contact.current?.scrollIntoView({behavior: 'smooth'});   hide_mobile_menu()};


    const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const [loaded, setloaded] = useState(false);
    const [language, setlanguage] = useState("az");
    const [show_langs, setshow_langs] = useState(false);

    const langu = 
        language === 'en' ? en 
        : language === 'rus' ? rus
        : az;


    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '500px',
        border: '2px #ff8500 solid',
        backgroundPosition : 'center'
    }
    const divStyle_mobile = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '300px',
        border: '2px #ff8500 solid',
        backgroundPosition : 'center'

    }


    const buttonStyle = {
        width: "80px",
        background: 'none',
        border: '0px'
    };

    const properties = {
        prevArrow: <button style={{ ...buttonStyle }}><TbChevronLeft size={80} color='#FFF'  /></button>,
        nextArrow: <button style={{ ...buttonStyle }}><TbChevronRight size={80} color='#FFF'  /></button>
    }

    const [slideImages, setSlideImages] = useState([]);

    const [galeri, setGaleri] = useState([]);

    const [teams, setTeams] = useState([]);


    const [sentence1_az, setsentence1_az] = useState("");
    const [sentence2_az, setsentence2_az] = useState("");
    const [sentence3_az, setsentence3_az] = useState("");
    
    const [sentence1_rus, setsentence1_rus] = useState("");
    const [sentence2_rus, setsentence2_rus] = useState("");
    const [sentence3_rus, setsentence3_rus] = useState("");

    const [sentence1_en, setsentence1_en] = useState("");
    const [sentence2_en, setsentence2_en] = useState("");
    const [sentence3_en, setsentence3_en] = useState("");

    const [sure_az, setsure_az] = useState("");
    const [yas_az, setyas_az] = useState("");
    const [kisi_az, setkisi_az] = useState("");
    const [aciklama_az, setaciklama_az] = useState("");

    const [sure_en, setsure_en] = useState("");
    const [yas_en, setyas_en] = useState("");
    const [kisi_en, setkisi_en] = useState("");
    const [aciklama_en, setaciklama_en] = useState("");

    const [sure_rus, setsure_rus] = useState("");
    const [yas_rus, setyas_rus] = useState("");
    const [kisi_rus, setkisi_rus] = useState("");
    const [aciklama_rus, setaciklama_rus] = useState("");

    const [address_az, setaddress_az] = useState("");
    const [address_rus, setaddress_rus] = useState("");
    const [address_en, setaddress_en] = useState("");
    const [tel, settel] = useState("");


    const [defaultProps, setdefaultProps] = useState(
        {
            center: {
            lat: 40.40202222486093,
            lng: 49.83142918650895
            },
            zoom: 16
        }
    );


    
    useEffect(() => { 
        getData()
    }, []);

    const getData = () => {
        axios.post(process.env.REACT_APP_API_BASE+'web_data?language='+language )
        .then(function (response) {

            if(response.data.result === 'Success'){
                //setBanners(response.data.banners)

                setsentence1_az(response.data.header_info.sentence1_az)
                setsentence2_az(response.data.header_info.sentence2_az)
                setsentence3_az(response.data.header_info.sentence3_az)
                
                setsentence1_rus(response.data.header_info.sentence1_rus)
                setsentence2_rus(response.data.header_info.sentence2_rus)
                setsentence3_rus(response.data.header_info.sentence3_rus)
                
                setsentence1_en(response.data.header_info.sentence1_en)
                setsentence2_en(response.data.header_info.sentence2_en)
                setsentence3_en(response.data.header_info.sentence3_en)

                let _banners = []
                response.data.banners.forEach(e => {

                    let _temp = {
                        url: process.env.REACT_APP_API_BASE + "uploads/" + e.image,
                        caption: ''
                    };
                    _banners.push(_temp)

                });

                setSlideImages(_banners)

                setloaded(true)


                setsure_az(response.data.nedir.sure_az)
                setsure_rus(response.data.nedir.sure_rus)
                setsure_en(response.data.nedir.sure_en)

                setyas_az(response.data.nedir.yas_az)
                setyas_rus(response.data.nedir.yas_rus)
                setyas_en(response.data.nedir.yas_en)

                setkisi_az(response.data.nedir.kisi_az)
                setkisi_rus(response.data.nedir.kisi_rus)
                setkisi_en(response.data.nedir.kisi_en)
                
                setaciklama_az(response.data.nedir.aciklama_az)
                setaciklama_rus(response.data.nedir.aciklama_rus)
                setaciklama_en(response.data.nedir.aciklama_en)


                let _teams = []
                response.data.teams.forEach(e => {

                    let _im = process.env.REACT_APP_API_BASE + "uploads/" + e.image;
                    if(e.image === ""){
                        _im = "";
                    }

                    let _temp = {
                        name: e.name,
                        puan: e.point,
                        image: _im,
                    };
                    _teams.push(_temp)

                });

                setTeams(_teams)


                let _gal = []
                response.data.gallery.forEach(e => {

                    let _temp = {
                        small: process.env.REACT_APP_API_BASE + "uploads/c_" + e.image,
                        big: process.env.REACT_APP_API_BASE + "uploads/" + e.image
                    };
                    _gal.push(_temp)

                });

                setGaleri(_gal)


                setaddress_az(response.data.contact.address_az)
                setaddress_rus(response.data.contact.address_rus)
                setaddress_en(response.data.contact.address_en)
                settel(response.data.contact.tel)

                setdefaultProps({
                    center: {
                        lat: parseFloat(response.data.contact.latitude),
                        lng: parseFloat(response.data.contact.altitude)
                    },
                    zoom: 16
                })

                

            }else{
            }

        });
    }

    useEffect(() => { 
        getData()
    }, [language]);



    const  show_lang_select = () => {  setshow_langs(!show_langs); }
    const  lang_select_rus = () => {setlanguage('rus'); setshow_langs(false);  hide_mobile_menu()}
    const  lang_select_az = () => {setlanguage('az'); setshow_langs(false);  hide_mobile_menu()}
    const  lang_select_en = () => {setlanguage('en'); setshow_langs(false);  hide_mobile_menu()}



    const [show_team_photo, setshow_team_photo] = useState(false);
    const [team_photo_big, setteam_photo_big] = useState("");


    const showImage = (gelen) => {
        setteam_photo_big(gelen)
        setshow_team_photo(true)
    }
    const closeLightbox = () => {
        setshow_team_photo(false)
    };

    const [show_mobile, setshow_mobile] = useState(false);
    const show_mobile_menu = () => {
        setshow_mobile(true)
    }
    const hide_mobile_menu = () => {
        setshow_mobile(false)
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }


    const [dur, setdur] = useState('all');


    const changeT = () => {
        setdur('all')
    }

    const changeH = () => {
        setdur('hafta')
    }


    useEffect(() => { 
        getDataTeams()
    }, [dur]);


    const getDataTeams = () => {
        axios.post(process.env.REACT_APP_API_BASE+'web_data_teams?dur='+dur )
        .then(function (response) {

            if(response.data.result === 'Success'){
                

                let _teams = []
                response.data.teams.forEach(e => {

                    let _im = process.env.REACT_APP_API_BASE + "uploads/" + e.image;
                    if(e.image === ""){
                        _im = "";
                    }

                    let _temp = {
                        name: e.name,
                        puan: e.point,
                        image: _im,
                    };
                    _teams.push(_temp)

                });

                setTeams(_teams)


            }else{
            }

        });
    }

    
    return(

        <div>

            <div className='header_div_full'>

                {isMobile ?
                <div className=' container cont_w d-flex justify-content-end position-relative p-0 '>
                    <a className='cursor' onClick={scrollTop}>
                    <img src={logo_img} width={180} className='position-absolute' style={{left:0, top:8}} />
                    </a>

                    <div className='py-3 text-end'>
                        <a onClick={show_mobile_menu}><TbMenu2 color='#FFF' size={32} /></a>
                    </div>

                </div>
                :
                <div className=' container cont_w d-flex justify-content-end position-relative p-0 '>
                    <a className='cursor' onClick={scrollTop}>
                    <img src={logo_img} width={240} className='position-absolute' style={{left:0, top:8}} />
                    </a>
                    <div className='py-4'>
                        <a onClick={gotoNedir} className='header_link cursor'>{langu.nedir}</a>
                        <a onClick={gotoPuan} className='header_link cursor'>{langu.puan_tabloso}</a>
                        <a onClick={gotoGalery} className='header_link cursor'>{langu.galeri}</a>
                        <a onClick={gotoContact} className='header_link cursor'>{langu.iletisim}</a>
                    </div>

                    <div className=' text-end py-4 position-relative' style={{width:65}}>
                        <div className='lang_selected '>
                            <a className='cursor' onClick={show_lang_select}>
                                {language === "az" ? 
                                    <img src={flag_az} width={30} className='' />
                                : language === "rus" ?
                                <img src={flag_rus} width={30}  className=''/>
                                :
                                <img src={flag_en} width={30} className='' />
                                }
                            </a>
                        </div>
                        {show_langs &&
                        <div className='position-absolute lang_menu'>
                            {language !== "az" && <a href='#'   onClick={lang_select_az}><img src={flag_az} width={30} className='my-1'  /></a> }
                            {language !== "rus" && <a href='#'   onClick={lang_select_rus}><img src={flag_rus} width={30} className='my-1'  /></a>}
                            {language !== "en" && <a href='#'   onClick={lang_select_en}><img src={flag_en} width={30} className='my-1'  /></a>}

                        </div>
                        }

                    </div>
                </div>
                }



            </div>

            <div className=' container cont_w py-3 px-0 cont_mt' >

                {isMobile ?

                    <>{loaded &&
                        <Slide  {...properties} transitionDuration={300}>
                            {slideImages.map((slideImage, index)=> (
                                <div key={index}>
                                    <div style={{ ...divStyle_mobile, 'backgroundImage': `url(${slideImage.url})` }}>
                                    </div>
                                </div>
                            ))} 
                        </Slide>
                    }</>
                :
                    <>{loaded &&
                        <Slide  {...properties} transitionDuration={300}>
                            {slideImages.map((slideImage, index)=> (
                                <div key={index}>
                                    <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                    </div>
                                </div>
                            ))} 
                        </Slide>
                    }</>
                }
                
                <div className='text-center mt-4' >
                    <img src={ev_07} width={36} />
                </div>
                <div className='text-center mt-3'>
                    {language === "az" &&
                    <>
                    <div>{sentence1_az}</div>
                    <div className='mt-1'>{sentence2_az}</div>
                    <div className='mt-1'>{sentence3_az}</div>
                    </>
                    }

                    {language === "rus" &&
                    <>
                    <div>{sentence1_rus}</div>
                    <div className='mt-1'>{sentence2_rus}</div>
                    <div className='mt-1'>{sentence3_rus}</div>
                    </>
                    }
                    {language === "en" &&
                    <>
                    <div>{sentence1_en}</div>
                    <div className='mt-1'>{sentence2_en}</div>
                    <div className='mt-1'>{sentence3_en}</div>
                    </>
                    }
                </div>


                <div className='mt-5 pt-5' ref={ref_nedir}>
                    
                    <div className='w600 f_22 baslik_b'>{langu.nedir}</div>

                    <div className='nedir_box mt-2'>
                        <div className={isMobile ? 'text-center mb-3' : 'float-start pe-5 me-5 border_right_w'} ><img src={logo_w} width={200} ></img></div>
                        <div className={isMobile ? 'color_t text-center' : 'float-start color_t'}>
                            <div>{langu.sure}<span className='mx-1'>:</span>  
                                {language === "az" &&  sure_az}
                                {language === "rus" &&  sure_rus}
                                {language === "en" &&  sure_en}
                            </div>
                            <div className='mt-2'>{langu.yas}<span className='mx-1'>:</span> 
                                {language === "az" &&  yas_az}
                                {language === "rus" &&  yas_rus}
                                {language === "en" &&  yas_en}
                            </div>
                            <div className='mt-2'>{langu.kisi}<span className='mx-1'>:</span> 
                                {language === "az" &&  kisi_az}
                                {language === "rus" &&  kisi_rus}
                                {language === "en" &&  kisi_en}
                            </div>
                        </div>
                        <div className='clearfix'></div>

                        <div className={isMobile ? 'bg_k p-2 minh mt-4 scrl' : 'bg_k p-3 mt-4 scrl'} style={{whiteSpace:'pre-line'}}>

                            {language === "az" &&  aciklama_az}
                            {language === "rus" &&  aciklama_rus}
                            {language === "en" &&  aciklama_en}
                        </div>
                    </div>

                </div>

                <div className='mt-5 pt-5' ref={ref_puan}>
                    
                    <div className='w600 f_22 baslik_b'>{langu.puan_tabloso}</div>
                    {isMobile ? 
                    <div className='bg_k text-center d-flex justify-content-center align-items-center p-2 mt-2'>
                        <img src={var1} width={45} />
                        <div className='w600 f_22 mx-1'>
                            <div>{langu.sampiyon_takimlar}</div>
                            <div>
                                <button onClick={changeT} className={dur === "all" ? 'button_ac f_14 px-2 py-1  border-0' : 'button_pas f_14 px-2 py-1  border-0' } >{langu.tum_zaman}</button>
                                <button onClick={changeH} className={dur === "hafta" ? 'button_ac f_14 px-2 py-1   border-0' : 'button_pas f_14 px-2 py-1   border-0' } >{langu.son_hafta}</button>
                            </div>    
                        </div>
                        <img src={var2} width={45} />
                    </div>
                    :
                    <div className='bg_k text-center d-flex justify-content-center align-items-center p-3 mt-2'>
                        <img src={var1} width={90} />
                        <div className='w600 f_40 mx-3'>
                            <div>{langu.sampiyon_takimlar}</div>
                            <div>
                                <button onClick={changeT} className={dur === "all" ? 'button_ac f_18 px-3 py-1 me-1 border-0' : 'button_pas f_18 px-3 py-1 me-1  border-0' } >{langu.tum_zaman}</button>
                                <button onClick={changeH} className={dur === "hafta" ? 'button_ac f_18 px-3 py-1 me-1  border-0' : 'button_pas f_18 px-3 py-1 me-1  border-0' } >{langu.son_hafta}</button>
                            </div>
                        </div>
                        <img src={var2} width={90} />
                    </div>
                    }
    
                    {isMobile ?
                        <div className='puan_box mt-2 scrl'>
                            
                            {teams.map((val,index) => (
                                
                                <div className='p-3 border_bottom_tur d-flex align-content-center' key={index}>

                                    <div className='me-4 f_40 w600 text-center myfont color_t' style={{width:75}}>
                                        {index === 0 ? <img src={s1} width={70} /> :
                                        index === 1 ? <img src={s2} width={70} /> :
                                        index === 2 ? <img src={s3} width={70} />
                                        :
                                        (index + 1)
                                        }
                                        </div>
                                    <div className='flex-grow-1'>
                                        <div className='color_t'>{val.name}</div>
                                        <div className='f_22'>{val.puan} P.</div>
                                    </div>
                                    {val.image !== "" &&
                                    <button onClick={() => showImage(val.image)} className='photo_btn p-1'><img src={ph_23} width={50} height={25} /></button>
                                    }
                                </div>
                                
                            ))}

                        </div>
                :
                    <div className='puan_box mt-2 scrl'>
                        
                        {teams.map((val,index) => (
                            
                            <div className='p-3 border_bottom_tur d-flex align-content-center' key={index}>

                                <div className='me-4 f_40 w600 text-center myfont color_t' style={{width:120}}>
                                    {index === 0 ? <img src={s1} width={110} /> :
                                    index === 1 ? <img src={s2} width={110} /> :
                                    index === 2 ? <img src={s3} width={110} />
                                    :
                                    (index + 1)
                                    }
                                    </div>
                                <div className='flex-grow-1'>
                                    <div className='color_t'>{val.name}</div>
                                    <div className='f_32'>{val.puan} P.</div>
                                </div>
                                {val.image !== "" &&
                                <button onClick={() => showImage(val.image)} className='photo_btn'><img src={ph_23} width={80} height={40} /></button>
                                }
                            </div>
                            
                        ))}

                    </div>
                }

                </div>

                <div className='mt-5 pt-5' ref={ref_galery}>
                    
                    <div className='w600 f_22 baslik_b'>{langu.galeri}</div>

                    <div className='d-flex mt-2 flex-wrap'>
                        {galeri.map((val,index) => (
                            <a key={index}  onClick={() => showImage(val.big)}>
                                <div className='gal_im' style={{backgroundImage: 'url('+val.small+')'}}></div>
                            </a>
                        ))}
                    </div>

                </div>

                <div className='mt-5 pt-5'>
                    
                    <div className='w600 f_22 baslik_b'>{langu.iletisim}</div>

                    <div className='ilet_box mt-2'>

                    <div style={{ height: '400px', width: '100%' }}>


                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyAQXu5brIeC9tr6DIU_1NH2l0jPBYbfiQs' }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            > 
                                <AnyReactComponent
                                    lat={defaultProps.center.latitude}
                                    lng={defaultProps.center.altitude}
                                    text="My ********** Marker"
                                    />
                            </GoogleMapReact>
                        
                        </div>


                        <div className='addres_pad'>
                            <div className={isMobile ? 'text-center f_20 mt-4' :'text-center f_28 mt-5'}>{langu.adres}<span className='mx-1'>:</span> 
                                {language === "az" ? address_az
                                : language === "rus" ? address_rus
                                : address_en
                                }

                            </div>
                            <div className={isMobile ? 'text-center f_20 mt-4 mb-4' :'text-center f_28 mt-5 mb-5'} >{langu.telefon}: {tel}</div>
                        </div>
                    </div>

                </div>



            </div>
        
            <div className='mt-5  text-center footer_box mb-4' ref={ref_contact}>
                <div className='py-5 footer_box2'>
                    <div className=''><img src={jun_37} width={200} /></div>
                    <div className={isMobile ? 'mt-4 f_20' : 'mt-4 f_22'}>{langu.adres}<span className='mx-1'>:</span> 
                        {language === "az" ? address_az
                        : language === "rus" ? address_rus
                        : address_en
                        }
                    </div>
                    <div className={isMobile ? 'mt-2 f_20' : 'mt-2 f_22'}>{langu.telefon}:  {tel}</div>
                </div>
            </div>

            {show_mobile &&
            <div className='mobile_menu_div'>

                    <div className='text-end p-3'>
                        <a className='cursor' onClick={hide_mobile_menu}>
                        <TbX color='#FFF' size={26} strokeWidth={4} /> 
                        </a>
                    </div>

                    <div className='py-4'>
                        <div className='mt-4'><a onClick={gotoNedir} className='header_link cursor'>NEDİR</a></div>
                        <div className='mt-4'><a onClick={gotoPuan} className='header_link cursor'>PUAN DURUMU</a></div>
                        <div className='mt-4'><a onClick={gotoGalery} className='header_link cursor'>GALERİ</a></div>
                        <div className='mt-4'><a onClick={gotoContact} className='header_link cursor'>İLETİŞİM</a></div>
                    </div>

                    <div className='text-center pt-4'>
                        <a  onClick={lang_select_az}><img src={flag_az} width={40} className={language == 'az' ? 'lang_selected_mobile my-1 cursor' : 'my-1 cursor' }/></a> 
                        <a  onClick={lang_select_rus}><img src={flag_rus} width={40} className={language == 'rus' ? 'lang_selected_mobile my-1 mx-4 cursor' : 'my-1 mx-4 cursor' } /></a>
                        <a  onClick={lang_select_en}><img src={flag_en} width={40} className={language == 'en' ? 'lang_selected_mobile my-1 cursor' : 'my-1 cursor' } /></a>

                    </div>

            </div>
            }

            {show_team_photo &&    
                <Lightbox
                hideZoom 
                hideDownload
                large={team_photo_big}
                alt=""
                onClose={closeLightbox}
                />
            }

        </div>


    );
}

export default Home;