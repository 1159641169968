import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Router>
);

reportWebVitals();