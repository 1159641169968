import React, { useState, useEffect , useRef} from 'react';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { TbChevronDown, TbChevronUp} from "react-icons/tb";

import C_dash_menu from './C_dash_menu'




const Adminpass= () => {

    

    const [email, setemail] = useState("");
    const [pass_cur, setpass_cur] = useState("");
    const [pass_new, setpass_new] = useState("");
    const [pass_new2, setpass_new2] = useState("");

    


    const handleemail = (e) => {setemail((e.target.value))}
    const handlepass_cur = (e) => {setpass_cur((e.target.value))}
    const handlepass_new = (e) => {setpass_new((e.target.value))}
    const handlepass_new2 = (e) => {setpass_new2((e.target.value))}

    





    function showError(gelen) {
        toast.error(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }

    function showSucess(gelen) {
        toast.success(gelen, {
            position: "top-right", autoClose: 1000,  hideProgressBar: false, closeOnClick: true,
            pauseOnHover: true, draggable: true, progress: undefined,
        });
    }


    useEffect(() => { 

        getNedir()


    }, []);

    const getNedir = () => {

        axios.post(process.env.REACT_APP_API_BASE+'admin_password' )
            .then(function (response) {

                console.log(response.data);

                if(response.data.result === 'Success'){
                    setemail(response.data.pass.email)

                }else{
                }

            });
    }

    const save1Click = () => {

        if(pass_new == pass_new2){

            let data = {
                email : email,
                pass_cur : pass_cur,
                pass_new : pass_new,
            };
    
    
            axios.post(process.env.REACT_APP_API_BASE+'admin_password_save',JSON.stringify(data)
            )
            .then(function (response) {
    
                if(response.data.result === 'Success'){
    
                    showSucess("Successfully saved!");
    
                }else{
                    showError("Your current password is wrong!");
                }
    
            });

        }else{
            showError("Please check new password and new password (again) fields. They must be same!");

        }

        

    }

    
    return(

        <div className=' container my-3  '>

            <div className='row'>
                <div className='col-3'>
                    <C_dash_menu />
                </div>
                <div className='col-9'>
                    <div className="card  border-0 mx-auto color_s " >

                        <div className="card-body p-3">
                            <div className='w600 f_20'>ADMIN LOGIN PASSWORD</div>


                            <div className=' mt-3'>
                                <>
                                    <div className='f_14'>E-MAIL: </div> 
                                    <div><input type="text" className="form-control f_14 border_grey" value={email} onChange={handleemail}  /></div>
                                
                                    <div className='f_14 mt-2'>CURRENT PASSWORD: </div> 
                                    <div><input type="password" className="form-control f_14 border_grey" value={pass_cur} onChange={handlepass_cur}  /></div>
                                
                                    <div className='f_14 mt-2'>NEW PASSWORD: </div> 
                                    <div><input type="password" className="form-control f_14 border_grey" value={pass_new} onChange={handlepass_new}  /></div>

                                    <div className='f_14 mt-2'>NEW PASSWORD (Again): </div> 
                                    <div><input type="password" className="form-control f_14 border_grey" value={pass_new2} onChange={handlepass_new2}  /></div>
                                </>  
                            
                            </div>

                            <div className="mt-3 text-end ">
                                <a href="#" onClick={save1Click} className="btn btn-primary f_16 w600">SAVE</a>
                            </div>


                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />


        </div>

    );
}

export default Adminpass;